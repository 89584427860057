<template>
  <div class="guide-traditional-detail">
    <GuideTradCardTv :program="program" />

    <div class="guide-traditional-arrows">
      <div
        class="guide-traditional-arrows-left"
        v-on:click.prevent="scrollLeft()"
        :class="{ disabled: disablePrevButton }"
      >
        <i class="zmdi zmdi-chevron-left"></i>
      </div>
      <div
        class="guide-traditional-arrows-right"
        v-on:click.prevent="scrollRight()"
        :class="{ disabled: disableNextButton }"
      >
        <i class="zmdi zmdi-chevron-right"></i>
      </div>
    </div>

    <div class="info-channel-header">
      <div class="info-channel-header-time time">{{ readableDateInfo }}</div>
      <div class="info-channel-header-title title">{{ getTextDetail }}</div>
      <div class="filters-toolbar">
        <Select
          cssClass="dropdown-channel"
          :list="selectDaysOptions"
          :onSelect="onSelectDia"
          :showFullLoader="false"
          :showSearch="false"
          :orderedList="false"
          :showButtonFilter="false"
          keyField="id"
          textField="name"
          :title="onLive"
          searchTitle="Elegir día"
          :defaultSelectedItem="defaultDay"
        />

        <Select
          cssClass="dropdown-channel"
          :list="channelsNameFilter"
          :onSelect="onSelectChannel"
          :showFullLoader="false"
          :showButtonFilter="false"
          keyField="idChannel"
          textField="name"
          title="Canal"
          searchTitle="Filtrar por canal:"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as dateHelper from "@/helpers/dateHelper.js";
import { blockAsset } from "@/helpers/parentalControl.js";

export default {
  name: "GuideTradDetail",

  inheritAttrs: false,

  components: {
    GuideTradCardTv: () => import("@/components/GuideTradCardTv.vue"),
    FiltersScroll: () => import("@/components/Filters-scroll.vue"),
    Select: () => import("@/components/Select.vue"),
  },

  props: {
    program: {
      type: Object,
      required: false,
      default: () => {
        return {
          title: "Sin información",
          time: Date.now(),
          poster: "",
          channel: {
            logo: "",
            lcn: "",
          },
        };
      },
    },
    startTime: {
      type: Number,
      required: true,
    },
    disableOnLeftSide: {
      type: Boolean,
      default: false,
      required: false,
    },
    disableOnRightSide: {
      type: Boolean,
      default: false,
      required: false,
    },
    isLive: {
      type: Boolean,
      default: true,
      required: false,
    },
  },

  data() {
    return {
      listChannel: [],
      filterChannels: [],
      filterChannelsText: "",
      filterDiaText: "En Vivo",
      defaultDay: {
        id: null,
        name: "",
        timeStamp: null,
      },
    };
  },

  created() {
    this.$epg.update().then((r) => {
      this.listChannel = this.$epg.getListChannels();
    });
  },

  mounted() {
    this.$bus.$on("delete-filter", self.ClearFIlter);
  },

  computed: {
    getDateReadable() {
      return dateHelper.getHumanReadDate(
        Date.now() + 2 * telecentro.tplay.core.utiles.SECONDS_EQ_24_HOURS * 1000
      );
    },
    getPoster() {
      return "background-image: url(" + this.program.poster + ");";
    },
    getTextDetail() {
      if (!this.program || this.program.idAsset === null) return "";

      if (this.isBlocked(this.program)) {
        return "Bloqueado";
      } else {
        return this.program.title;
      }
    },
    readableDateInfo() {
      if (this.program.time) {
        //console.log('readableDateInfo', this.program)
        return `${this.program.fechaInicio} de ${this.program.time}`;
      } else {
        return "";
      }
    },
    disablePrevButton() {
      // TODO: está pendiente la funcionalidad para aceptar mas días
      // if (this.prevProgramButtonText !== 'Ayer' && this.prevProgramButtonText !== 'Hoy') {
      //     return true;
      // } else {
      //     return false;
      // }
      return this.disableOnLeftSide;
    },
    disableNextButton() {
      // TODO: está pendiente la funcionalidad para aceptar mas días
      // if (this.nextProgramButtonText !== 'Mañana' && this.nextProgramButtonText !== 'Hoy') {
      //     return true;
      // } else {
      //     return false;
      // }
      return this.disableOnRightSide;
    },
    channelsNameFilter() {
      return this.findBy(
        this.listChannel,
        this.filterChannelsText.toUpperCase(),
        "name"
      );
    },
    selectDaysOptions() {
      //TODO
      // Esto se debe mejorar, es una solución provisoria ya que
      // la epg puede que venga con mas cantidad de dias.

      let response = [];
      for (let index = -1; index < 3; index++) {
        response.push({
          id: index + 1,
          name: dateHelper.getHumanReadDate(this.getTimeStampDay(index), true),
          timeStamp: this.getTimeStampDay(index),
        });
      }
      //console.log("days options", response);
      return response;
    },
    onLive() {
      //console.log('onLive', this.isLive, this.filterDiaText);
      if (!this.isLive && this.filterDiaText === "En Vivo") {
        return "Hoy";
      } else {
        return this.filterDiaText;
      }
    },
  },

  methods: {
    scrollLeft() {
      if (!this.disablePrevButton) {
        this.$emit("scroll-left");
      }
    },
    scrollRight() {
      if (!this.disableNextButton) {
        this.$emit("scroll-right");
      }
    },
    getDateTime(date) {
      return dateHelper.getHumanReadDate(date);
    },
    isBlocked(program) {
      return blockAsset(program);
    },
    findBy(list, value, column) {
      return list.filter(function (item) {
        let name = item[column].toUpperCase();
        return name.includes(value);
      });
    },

    onSelectDia(dia) {
      //console.log("day filter add", dia);
      this.filterDiaText = dia.name;
      this.$emit("go-to-day", this.getTimeStampDay(dia.id - 1));
    },

    prevProgramButtonText() {
      const self = this;
      return dateHelper.getHumanReadDate(
        self.startTime - telecentro.tplay.core.utiles.SECONDS_EQ_24_HOURS * 1000
      );
    },
    nextProgramButtonText() {
      const self = this;
      return dateHelper.getHumanReadDate(
        self.startTime + telecentro.tplay.core.utiles.SECONDS_EQ_24_HOURS * 1000
      );
    },

    onSelectChannel(channel) {
      //console.log("scroll to channel", channel);
      this.$emit("scroll-to-channel", channel);
    },

    ClearFIlter() {
      this.filterChannels = [];
    },

    getTimeStampDay(offset) {
      return (
        Date.now() +
        offset * telecentro.tplay.core.utiles.SECONDS_EQ_24_HOURS * 1000
      );
    },
  },
  watch: {
    startTime(value) {
      const self = this;
      const day = dateHelper.getHumanReadDate(value, true);
      //console.log('watch startTime', day);

      if (self.defaultDay.name != day) {
        const options = self.selectDaysOptions;
        const objectDay = options.filter((p) => p.name === day)[0];
        if (objectDay) {
          self.defaultDay = objectDay;
          self.filterDiaText = objectDay.name;
        }
      }
    },
  },
};
</script>
